import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";

//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});

$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region slider
$(function () {
  var baseUrl = window.location.origin + '/indagri.com';
  console.log('baseUrl: ');
  console.log(baseUrl);
  $(".sliderhome").slick({
    infinite: true,
    slidesToShow: 1,
    dots: true,
    arrows: true,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
    nextArrow: '<button type="button" class="slick-next"><img src="' + baseUrl + '/images/caret-right-white.svg" width="19" height="33" alt="avanti" class="img-fluid" /></button>',
    prevArrow: '<button type="button" class="slick-prev"><img src="' + baseUrl + '/images/caret-left-white.svg" width="19" height="33" alt="indietro" class="img-fluid" /></button>',
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-image-link").slick({
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    appendArrows: $(".slider-image-link-arrow-container"),
    prevArrow: ".slider-image-link-prev-arrow",
    nextArrow: ".slider-image-link-next-arrow",
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-brand").slick({
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    appendArrows: $(".slider-brand-arrow-container"),
    prevArrow: ".slider-brand-prev-arrow",
    nextArrow: ".slider-brand-next-arrow",
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  });
});
//#endregion
